import { Link } from "gatsby";
import React, { ReactNode } from "react";
import Layout from "../components/layouts/Layout";
import SEO from "../components/SEO";
import AvatarSm from "./AvatarSm";
import css from "./Page.module.css";

type Props = {
  title: string;
  children: ReactNode;
};

export default function Page(props: Props) {
  return (
    <Layout>
      <section className={css.content}>
        <SEO title={props.title} />
        <div className={css.avatarContainer}>
          <Link to="/">
            <AvatarSm />
          </Link>
        </div>
        {props.children}
      </section>
    </Layout>
  );
}
