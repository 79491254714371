import { Link } from "gatsby";
import React from "react";
import bikes from "../images/bikes.jpg";
import A from "./A";
import Page from "./Page";

export default function PoliticalPage() {
  return (
    <Page title="Politics">
      <h1 className="text-3xl">Hi. I'm Matt.</h1>
      <p>
        I'm a concerned American. We've been asleep at the wheel and{" "}
        <A href="/adita.mp4">it's time to wake up</A>.
      </p>
      <p>
        This is the greatest country on Earth because it can take anyone from
        anywhere and turn them into an American, and Americans do incredible
        things.
      </p>
      <p>
        Lately we've seen the deepest ideals of our country, ideals like free
        expression and equality under the law, questioned and threatened by
        cynical, nihilistic forces who are ashamed of America and want to
        destroy it.
      </p>
      <p>
        <b>
          <i>
            To all Americans: if you truly love this country&mdash;
            <b>
              <i>our country</i>
            </b>
            &mdash;now is the time to stand up and act.
          </i>
        </b>
      </p>
      <p>
        We believe{" "}
        <i>
          every American, no matter what they look like or where their ancestors
          lived, is created equal and remains equal under the law
        </i>
        .
      </p>
      <p>
        To the extent that we fail to live up to that standard, we should want
        to do better, but if we lose the value itself, we are{" "}
        <b>
          <i>toast</i>
        </b>
        , <A href="https://www.youtube.com/watch?v=XoI6e60-87I">and</A>{" "}
        <A href="https://www.youtube.com/watch?v=hzWiHUE6YLE">we're</A>{" "}
        <A href="https://www.cjr.org/analysis/capital-b-black-styleguide.php">
          getting
        </A>{" "}
        <A href="https://www.youtube.com/watch?v=mzPKk19t3Kw">alarmingly</A>{" "}
        <A href="https://www.universityofcalifornia.edu/press-room/uc-board-regents-endorses-aca-5-repeal-prop-209">
          close
        </A>
        .
      </p>

      <p>
        When I'm not steeping myself in the zeitgeist or calling{" "}
        <A href="https://twitter.com/realDonaldTrump">patriots</A> into action,
        sometimes I <A href="https://github.com/mjesuele">write code</A>, lift
        heavy weights, ride a fast bike (<A href={bikes}>mine is on the left</A>
        ), play guitar, write, and wander around a{" "}
        <b>
          <i>lot</i>
        </b>
        .
      </p>
      <p>
        If you're bored, maybe you should read my <Link to="/blog">blog</Link>.
      </p>
    </Page>
  );
}
